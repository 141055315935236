import React from "react";
import style from "./Sale.module.css"
import NotFount from "../NotFount/NotFount";
import {NavLink} from "react-router-dom";
import DOR_ava from "../../images/russen_rottenschild_dorhan.png";
import RUSSEN_ROTTENSCHILD_YAVA from "../../images/RUSSEN_ROTTENSCHILD_YAVA.png";
import VIVA from "../../images/dogs/RR_VIVA.JPG";
import PuppyDorhanYava from "./SaleDog/PuppyDorhanYava";
import SherifrotthausHoward from "../../images/Sherifrotthaus_Howard.JPG";
import DOMINATOR from "../../images/dogs/DOMINATOR.JPG";
import RR_DIAMANTA from "../../images/dogs/RR_DIAMANTA.JPG";
import DexterHead from "../../images/dogs/Dexter/Dexter_head.JPG";
import TEYA from "../../images/dogs/RUSSEN ROTTENSCHILD TEYA/head.jpg";

const Sale = () => {
    return(


    <div>
        <div className={`${style.blokFont} ${style.fontLink}`}>
            <p align="center">
                <NavLink to="/Dexter" activeClassName={style.activeLink}>DEXTER V.D.
                    WEISSENSTADT</NavLink> &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &  &nbsp;
                <NavLink to="/Teya" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD TEYA</NavLink>
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;<img src={DexterHead} width="300" height="300" hspace="100"/>
            <img src={TEYA} width="300" height="300"/>
        </div>

        <div>
            <p className={style.text}>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;&nbsp;
                Представляем родителей помета в нашем питтомнике !<br/>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;&nbsp;
                Дата рождения 12 декабря 2024 года. 9 щенков 3 кобеля и 6 сук. <br/>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;&nbsp;
                Доступны для бронирования 2 кобеля и 3 суки.<br/>

            </p>
        </div>
        {/*  <div className={`${style.blokName} ${style.blokNameFont}`}>*/}
        {/*    <p align="center">RUSSEN ROTTENSCHILD DOMINATOR & RUSSEN ROTTENSCHILD DIAMANTA</p>*/}

        {/*</div>*/}
        {/*<div className={`${style.blokFont} ${style.fontLinkSale}`}>*/}
        {/*    <NavLink to="/PuppyDOMINATORDIAMANTA" >*/}
        {/*        <p className={style.NEWS} align="center" >Щенков от пары:*/}
        {/*            <br/>щенки родились: 23.02.2021 </p></NavLink>*/}
        {/*</div>*/}
        {/*<div className={`${style.blokFont} ${style.fontLink}`}>*/}
        {/*    <p align="center">*/}
        {/*        <NavLink to="/DOMINATOR" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DOMINATOR</NavLink> &*/}
        {/*        <NavLink to="/DIAMANTA" activeClassName={style.activeLink}>RUSSEN ROTTENSCHILD DIAMANTA</NavLink> </p>*/}
        {/*    <img src={DOMINATOR} width="300" height="300" hspace="100"/>*/}
        {/*    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<img src={RR_DIAMANTA} width="300" height="300"/>*/}
        {/*</div>*/}
    </div>
    );
}
export default Sale;